/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import Logo from "../logo/Logo";
import {
  ToggleMiniSidebar,
  ToggleMobileSidebar,
} from "../../store/customizer/CustomizerSlice";
// import qr from '../../assets/images/icons/qr.png';
import phonepe from "../../assets/images/icons/phonepe.png";
import gpay from "../../assets/images/icons/gpay.png";
import paytm from "../../assets/images/icons/paytm.png";
import upi from "../../assets/images/icons/upi.png";
// import qrImage from '../../assets/images/download.png';
import { logout } from "../../slices/authSlice/userSilce";
// import UserBalance from '../../components/common/userBalance';
import { fetchData } from "../../slices/authSlice/dataSlice";
// import { fetchUserControlData } from '../../slices/authSlice/getControlSlice';
import user1 from "../../assets/images/users/user1.jpg";
import ProfileDD from "./ProfileDD";
import ReactStrapModal from "../../components/common/reactStrapModal";
import CreditSelfAdmin from "../../views/Admin/ManageCreditDetails/CreditSelfAdmin";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const baseB4 = "data:image/jpeg;base64,";

  // const balanceUpdate = useSelector((state) => state.userdetails.userDeleteSuccess);
  // const [userBalance, setUserBalance] = useState(0);
  const [proData, setProData] = useState([]);
  const [QRimage] = useState();
  const [UPIID] = useState();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openWalletModal, setOpenWalletModal] = useState(false);

  useEffect(() => {
    dispatch(fetchData({ Usertoken, currentUser }));
  }, [dispatch]);
  const loginData = useSelector((state) => state.data.items);
  const handleLogoutClick = () => {
    window.addEventListener("beforeunload", () => {
      dispatch(logout());
    });
    navigate("/login", { replace: true });
    window.location.reload();
  };

  const toggle = () => {
    setModal(!modal);
  };
  // const handleRefresh = () => {
  //   window.location.reload();
  // };
  // console.log(loginData);
  return (
    <>
      <Navbar
        color={
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8"
            ? topbarColor
            : topbarColor
        }
        dark={!isDarkMode}
        light={isDarkMode}
        expand="lg"
        className={`${
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8"
            ? "topbarAdmin admin_head_bg"
            : "topbar"
        } ${
          currentUser.role == "1" || currentUser.role == "2" ? "px-md-2" : ""
        }`}
        loading={loading}
      >
        {/********Logo*******/}
        <div
          className={
            currentUser.role == "1" ||
            currentUser.role == "2" ||
            currentUser.role == "8"
              ? "d-lg-flex align-items-center logo-space"
              : "logo-spaceAdmin"
          }
        >
          {currentUser.role == "1" || currentUser.role == "2" ? (
            <Link to="/Dashboards/AdminDashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "5" ? (
            <Link to="/dashboards/distributordashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "4" ? (
            <Link to="/dashboards/superdistributordashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "3" ? (
            <Link to="/Dashboards/MasterDistributorDashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "6" ? (
            <Link to="/dashboards/retailerdashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "8" ? (
            <Link to="/dashboards/retailerdashboard">
              <Logo />
            </Link>
          ) : null}
          {/* <Button
            size="sm"
            className="ms-auto d-sm-block d-lg-none"
            onClick={() => dispatch(ToggleMobileSidebar())}
          >
            <i className="bi bi-list" />
          </Button> */}
        </div>
        <Button
                color={topbarColor}
                className=" mx-1 border-0 hov-dd minisidebar bg-secondary d-lg-block d-md-none d-sm-none d-ex-none"
                onClick={() => dispatch(ToggleMiniSidebar())}
              >
                <Icon.Menu size={18} />
              </Button>
        <div className="d-flex align-items-center">
          {currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8" ? (
            <>
              <Button
                color={topbarColor}
                className=" mx-1 border-0 hov-dd minisidebar bg-secondary d-none"
                onClick={() => dispatch(ToggleMiniSidebar())}
              >
                <Icon.Menu size={18} />
              </Button>
              <Button
                color={topbarColor}
                className=" border-0 mx-1 hov-dd d-none"
                onClick={() => dispatch(ToggleMobileSidebar())}
              >
                <i className="bi bi-list" />
              </Button>
            </>
          ) : null}
          <Button
            color={topbarColor}
            className="d-sm-block d-lg-none border-0 mx-1 hov-dd minisidebar bg-secondary"

            onClick={() => dispatch(ToggleMobileSidebar())}
          >
            <i className="bi bi-list" />
          </Button>
        </div>
        {currentUser.role == "1" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Admin Dashboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none" onClick={() => setOpenWalletModal(true)}>
                <i className="bi bi-wallet2 text-white fs-3" />
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "2" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Business Partner Dashboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "8" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Super Admin Dashboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "4" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Super Distributor!{" "}
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "5" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Distributor!{" "}
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "6" ? (
          <>
            <Nav className=" d-flex flex-row  " navbar>
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4
                    className="m-auto fw-bold"
                    // style={{ position: 'absolute', left: '20px', top: '15px' }}
                  >
                    {" "}
                    Dashboard - WELCOME, Agent!{" "}
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {/* <Nav className="me-auto d-flex flex-row align-items-center" navbar>
          {currentUser.role !== '1' ? (
            <NavItem className="d-md-block d-none ">
              <strong className="text-info">Office Whatsapp No. : 9876543210</strong>
            </NavItem>
          ) : null}
        </Nav> */}
        <div className="d-flex align-items-center">
          {/* {currentUser.role === '1' ? (
            <>
              <div className="p-0 px-2">
                <Link to="/Dashboards/AdminDashboard">
                  <Button color="info" size="md">
                    Dashboard
                  </Button>
                </Link>
              </div>
            </>
          ) : null} */}
          {/* {currentUser.role === '1' ? (
            <>
              <div className="p-0 px-2 mx-4">
                <Button color="info" size="md" onClick={() => handleRefresh()}>
                  Refresh
                </Button>
              </div>
              <div className="pt-0 px-3" onClick={() => toggle()}>
                <img src={qr} alt="qr" style={{ width: '30px', background: '#6732a7' }} />
              </div>
            </>
          ) : null} */}
          <div className="pt-2 px-1 d-flex">
            <div>
              {currentUser.role == "6" ? (
                <h4 className="text-warning fw-normal">
                  AEPS Wallet : {`\u20B9`}{" "}
                  {loginData ? loginData?.aepS_Wallet_Balance : "0.00"}
                </h4>
              ) : null}
            </div>
            <h4 className="text-warning fw-normal  px-3">
              Main Wallet : {`\u20B9`}{" "}
              {loginData ? loginData?.main_Wallet_Balance : "0.00"}
            </h4>
          </div>
          <UncontrolledDropdown>
            <DropdownToggle color="transparent">
              <div className="d-flex align-items-center">
                <img
                  src={baseB4 + proData}
                  alt="user"
                  width="30"
                  className="rounded-circle d-none"
                />
                <img
                  src={user1}
                  alt="user"
                  width="30px"
                  height="auto"
                  className="rounded-circle mx-1"
                />
                <span className="text-info">
                  {loginData?.firstName} {loginData?.lastName}
                </span>
                <Icon.ChevronDown size={15} color="#3fa2db" />
              </div>
            </DropdownToggle>
            <DropdownMenu className="ddWidth profile-dd">
              <ProfileDD />
              <div className="p-2 px-3">
                <Button color="danger" size="sm" onClick={handleLogoutClick}>
                  Logout
                </Button>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Navbar>

      {modal ? (
        <ReactStrapModal
          modal={modal}
          setModal={() => toggle(null)}
          isHeader
          isToggle
          title="UPI Collection"
          className="editModelDmt"
        >
          <Row className="row">
            <Col md="4">
              <img
                src={baseB4 + QRimage}
                alt="123"
                style={{ width: "150px", border: "1px solid #dfe2e6" }}
              />
            </Col>
            <Col md="8" className="mt-0">
              <Table className="table-bordered">
                <thead>
                  <tr>
                    <td className="text-nowrap text-black">Name </td>
                    <td>
                      {loginData.firstName}
                      {loginData.lastName}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-nowrap text-black">UPI ID </td>

                    <td>{UPIID}</td>
                  </tr>
                </thead>
              </Table>
              <Row>
                <img src={phonepe} alt="phonepe" style={{ width: "60px" }} />
                <img src={gpay} alt="gpay" style={{ width: "60px" }} />
                <img src={upi} alt="upi" style={{ width: "60px" }} />
                <img src={paytm} alt="paytm" style={{ width: "60px" }} />
              </Row>
            </Col>
          </Row>
        </ReactStrapModal>
      ) : null}

      {openWalletModal && (
        <ReactStrapModal
          modal={openWalletModal}
          setModal={() => setOpenWalletModal(false)}
          isHeader
          isToggle
          title="Self TopUp"
          className="editModelDmt"
          size='sm'
          styles={{width: '400px'}}
        >
          <CreditSelfAdmin setOpenWalletModal={() => setOpenWalletModal(false)} />
        </ReactStrapModal>
      )}
    </>
  );
};

export default Header;
