import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Encrypt,
  Decrypt,
  EncryptPreLogin,
  DecryptPreLogin,
} from '../../../middleware/CryptoMiddleWare';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  // creditAmount
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}
export const getSuperDistributor = createEncryptedAsyncThunk(
  'UserManagement/getSuperDistributor',
  'account/GetUserDetails',
  {
    prepareBody: (params) => ({ userID: params.UserID }),
  },
);
export const getAllUsers = createEncryptedAsyncThunk(
  'UserManagement/getAllUsers',
  `account/GetUserDetailsByRole`, // Function that takes params and returns the URL
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      role: params.roleId,
      documentRequired: params.docs ? params.docs : 0,
      status: 0,
    }),
  },
);
export const resetPasswordUser = createEncryptedAsyncThunk(
  'UserManagement/resetPasswordUser',
  `Service/ResetPassword`, // Function that takes params and returns the URL
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      resetUserId: params.data.userID,
      ipaddress: params.location.ip,
    }),
  },
);
export const updateIdAllot = createEncryptedAsyncThunk(
  'UserManagement/updateIdAllot',
  `account/UpdateIDAllotment`, // Function that takes params and returns the URL
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      requestUserID: Number(params.usrId),
      key: params.idAllotmentKey,
      value: params.idAllotmentValue,
    }),
  },
);

export const getUsersServiceStatus = createEncryptedAsyncThunk(
  'UserManagement/getUsersServiceStatus',
  `Service/GetUserServiceStatusDetails`, // Function that takes params and returns the URL
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ipAddress: params.location.ip,
    }),
  },
);

export const getAllUsersPreLogin = createAsyncThunk(
  'UserManagement/getAllUsersPreLogin',
  async (params) => {
    const rowData = {
      userID: params.currentUser.userGuid,
      preloginuserid: params.userIdPre,
      ipAddress: '192.128.11.103',
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}account/GetPreLoginUserDetails`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          PT: process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${params.Usertoken}`,
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      },
    );
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  },
);
export const RejectPreLoginUser = createEncryptedAsyncThunk(
  'UserManagement/RejectPreLoginUser',
  'account/RejectUserPreLogin',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      preUserID: `${params.userID.userid}`,
      reason: params.reason,
    }),
  },
);

export const getAllUsersPreLoginDetails = createAsyncThunk(
  'UserManagement/getAllUsersPreLoginDetails',
  async (params) => {
    const rowData = {
      userID: params.currentUser.userGuid,
      preloginuserid: `${params.userPre.userid}`,
      ipAddress: '192.128.11.103',
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}account/GetPreLoginUserDetails`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          PT: process.env.REACT_APP_API_KEY,
          Authorization: `Bearer ${params.Usertoken}`,
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      },
    );
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  },
);

export const getDocumentList = createEncryptedAsyncThunk(
  'UserManagement/getDocumentList',
  'account/GetMasterDataList',
  {
    prepareBody: (params) => ({
      role_Id: [0],
      masterDatakey: 'Document',
      userID: params.currentUser.userGuid,
    }),
  },
);
export const UploadDocumentList = createEncryptedAsyncThunk(
  'UserManagement/UploadDocumentList',
  'account/AddUserDocuments',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      adduserId: `${params.userID}`,
      dataKeyValueLists: params.formData,
      created_By: params.currentUser.userId,
    }),
  },
);

export const updateUserDetailKyc = createEncryptedAsyncThunk(
  "UserManagement/updateUserDetailKyc",
  "account/UpdateUserDetails",
  {
    prepareBody: (params) => {
      const todayDate = new Date().toISOString();
      return {
        userID: params.currentUser.userGuid,
        updateUserID: params.userId,
        otp: "",
        emailAddress: params.email || "",
        mobile: params.mobile || "",
        plan_Id: params.planId || 0,
        primaryPhoneType: params.mobile || "",
        status: 0,
        pincode: params.pincode || "",
        whatsapp_no: params.whatsapp_no,
        block_add: params.block_add,
        gram_panchayat: params.gram_panchayat,
        post_office: params.post_office,
        police_thana: params.police_thana,
        gram: params.gram,
        other: params.other || '',
        updatedBy: 0,
        secondryno: params.secondryno,
        landmark: params.landMark,
        business_Name: params.business_Name,
        business_Contact_No: params.business_Contact_No,
        business_Add: params.business_Add,
        dob: params.dob || todayDate,
        gender: params.gender || 0,
        locationtype: params.locationtype || 0,
      };
    },
  }
);

export const getUserDocuments = createEncryptedAsyncThunk(
  'UserManagement/getUserDocuments',
  'account/GetUserDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      userDetailsID: params.userID,
      documentRequired: 1,
    }),
  },
);
export const UpdateKYCDocuments = createEncryptedAsyncThunk(
  'UserManagement/UpdateKYCDocuments',
  'account/UpdateUserDocuments',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      updateuserId: `${params.userID}`,
      dataKeyValueLists: params.notification,
      created_By: params.currentUser.userId,
    }),
  },
);
export const verifyPANCard = createEncryptedAsyncThunk(
  'UserManagement/verifyPANCard',
  'account/PSPanVerify',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      refid: '',
      pannumber: params.panNumber,
    }),
  },
);
export const VerifyAdharOTPAdhar = createEncryptedAsyncThunk(
  'UserManagement/VerifyAdharOTPAdhar',
  'account/PSAadharVerifyOTP',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      otp: params.otp,
      client_id: params.clientRefId,
    }),
  },
);
export const submitKYCDetailsAdmin = createEncryptedAsyncThunk(
  'UserManagement/submitKYCDetailsAdmin',
  'account/UpdateEKyc',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      updateUserId: Number(params.userD.userID),
      dataKeyValueLists: [
        {
          dataKey: 'ADHARCARDNO',
          // dataValue: params.panNumber ? params.panNumber : params.adharNumber,
          dataValue: params.adharNumber,
          dataValue1: JSON.stringify(params.adharData),
          dataValue2: '',
          dataValue3: '',
          dataStatus: 0,
        },
      ],
      created_By: 0,
      updateKYCDataFlag: true,
    }),
  },
);
export const submitKYCDetails = createEncryptedAsyncThunk(
  'UserManagement/submitKYCDetails',
  'account/UpdateEKyc',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      updateuserID: params.currentUser.userId,
      dataKeyValueLists: [
        {
          dataKey: params.transType,
          dataValue: params.panNumber ? params.panNumber : params.adharNumber,
          dataValue1: JSON.stringify(params.useDetails),
          dataValue2: '',
          dataValue3: '',
          dataStatus: 0,
        },
      ],
      created_By: 0,
      updateKYCDataFlag: false,
    }),
  },
);

export const sendOTPAdhar = createEncryptedAsyncThunk(
  'UserManagement/UpdateKYCDocuments',
  'account/PSAadharGenerateOTP',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      id_number: params.adharNumber,
    }),
  },
);
export const createUsers = createEncryptedAsyncThunk(
  'UserManagement/createUsers',
  'account/CreateUser',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      nameTitle: params.title,
      username: '',
      password: '',
      tpin: '',
      emailAddress: params.body.emaiID,
      firstName: params.body.firstName,
      middleName: params.body.middleName,
      lastName: params.body.lastName,
      primaryPhone: params.body.contactNumber,
      primaryPhoneType: 0,
      secondaryPhone: params.body.altContactNumber,
      secondaryPhoneType: 0,
      role: params.roleId,
      senior_ID: params.seniorID,
      business_Name: params.body.businessName,
      business_Add: params.businessAddress,
      business_Contact_No: params.body.businessContact,
      business_Type: 'string',
      state_ID: params.selectState.value,
      city_ID: params.selectCity.value,
      pincode: params.body.pincode,
      area: 'string',
      landMark: params.body.landmark,
      address: params.address,
      created_By: 0,
      plan_id: 0,
      whatsapp_no: params.body.whatupNo,
      block_add: params.body.block,
      gram_panchayat: params.body.grampanchayat,
      post_office: params.body.postoffice,
      police_thana: params.body.policethana,
      gram: params.body.gram,
      other: '',
      custom_Attributes: {
        ip: params.location.ip,
        deviceId: '',
        // deviceType: '',
        deviceType: params.location.deviceType,
        lattitude: params.location.lattitude,
        longitude: params.location.longitude,
      },
    }),
  },
);
export const getRolewiseUser = createEncryptedAsyncThunk(
  'UserManagement/getRolewiseUser',
  'account/GetUserDetailsByRole',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      role: params.roleId,
      documentRequired: 0,
      status: 0,
    }),
  },
);
export const getRoleUserList = createEncryptedAsyncThunk(
  'UserManagement/getRoleUserList',
  'account/GetUserDetailsByRole',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      role: params.roleId,
      documentRequired: 0,
      status: 0,
    }),
  },
);
export const getUserProfileDetails = createEncryptedAsyncThunk(
  'UserManagement/getUserProfileDetails',
  'account/GetUserDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      userDetailsID: Number(params.userD.userID),
      documentRequired: 1,
    }),
  },
);
export const UpdatePersonalDetails = createEncryptedAsyncThunk(
  'UserManagement/UpdatePersonalDetails',
  'Admin/UpdatePersonalDetails',
  {
    prepareBody: (params) => ({
      userId: params.RowID,
      nameTitle: 'Mr',
      fName: params.firstName,
      lName: params.lastName,
      altContactNumber: params.mobile,
      emailID: params.email,
      address: params.address,
    }),
  },
);

export const updateUserDetail = createEncryptedAsyncThunk(
  "UserManagement/updateUserDetail",
  "account/UpdateUserDetails",
  {
    prepareBody: (params) => {
      return {
        userID: params.currentUser.userGuid,
        updateUserID: params.userId,
        otp: "",
        emailAddress: params.email || "",
        mobile: params.mobile || "",
        plan_Id: params.planId || 0,
        primaryPhoneType: params.mobile || "",
        status: params.status || 0,
        pincode: params.pincode || "",
        whatsapp_no: params.whatsapp_no,
        block_add: params.block_add,
        gram_panchayat: params.gram_panchayat,
        post_office: params.post_office,
        police_thana: params.police_thana,
        gram: params.gram,
        other: params.other || '',
        updatedBy: params.updatedBy || 0,
        secondryno: params.secondryno || '',
        landmark: params.landmark || '',
        business_Name: params.business_Name || '',
        business_Contact_No: params.business_Contact_No || '',
        business_Add: params.business_Add || '',
        dob: params.dob || null,
      };
    },
  }
);

export const PlanUpdate = createEncryptedAsyncThunk(
  'UserManagement/PlanUpdate',
  'account/UpdateUserDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      updateUserID: params.userId,
      otp: '',
      emailAddress: params.email,
      mobile: params.mobile,
      plan_Id: JSON.parse(params.planId.value),
      primaryPhoneType: params.mobile,
      status: 1,
      pincode: '',
      whatsapp_no: '',
      block_add: '',
      gram_panchayat: '',
      post_office: '',
      police_thana: '',
      gram: '',
      other: '',
      updatedBy: 0,
    }),
  },
);
export const FillDropDownDetailsServices = createAsyncThunk(
  'UserManagement/UpdatePersonalDetails',
  async (params) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}Modules/FillDropDownDetails?Flag=${params.flag}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${params.Usertoken}`,
        },
      },
    );
    const data = await response.text();
    const res = JSON.parse(Decrypt(data));
    return res;
  },
);

export const createRole = createAsyncThunk('UserManagement/createRole', async (params) => {
  const rowData = {
    role_ID: 0,
    roleName: params.roleName,
    statusID: 0,
    is_Active: 1,
    reporting_id: params.selectedRole.value,
    reporting_Name: params.selectedRole.label,
  };

  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}Modules/AddRole`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      PT: process.env.REACT_APP_API_KEY,
      Authorization: `Bearer ${params.Usertoken}`,
    },
    body: Encrypt(JSON.stringify(rowData)),
  });
  const data = await response.text();
  const res = JSON.parse(Decrypt(data));
  return res;
});
export const getRoles = createEncryptedAsyncThunk(
  'UserManagement/getRoles',
  'account/GetUserRoles',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
    }),
  },
);
export const changeStatus = createEncryptedAsyncThunk(
  'UserManagement/changeStatus',
  'account/UpdateUserDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      updateUserID: `${params.data.userID}`,
      otp: '',
      emailAddress: `${params.data.emailAddress}`,
      mobile: `${params.data.primaryPhone}`,
      plan_Id: params.data.plan_id,
      primaryPhoneType: `${params.data.primaryPhone}`,
      status: params.value,
      pincode: '',
      whatsapp_no: '',
      block_add: '',
      gram_panchayat: '',
      post_office: '',
      police_thana: '',
      gram: '',
      other: '',
      updatedBy: 0,
    }),
  },
);
export const onboardingPreUser = createEncryptedAsyncThunk(
  'UserManagement/onboardingPreUser',
  'account/CreateUserFromPreLogin',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      preUserId: params.userPre.userid,
      ipAddress: '192.128.11.103',
    }),
  },
);
export const getTransactionAmount = createEncryptedAsyncThunk(
  'UserManagement/getTransactionAmount',
  'account/GetPGAmountMasterData',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      key: 'RETAILERPG',
    }),
  },
);
export const setKYCAmount = createEncryptedAsyncThunk(
  'UserManagement/getTransactionAmount',
  'account/UpdatePGAmountMasterData',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      key: 'RETAILERPG',
      value: params.amount,
    }),
  },
);
export const getState = createAsyncThunk('UserManagement/getState', async () => {
  const rowData = {
    userid: 0,
  };
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}account/GetStateList`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      PT: process.env.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    },
    body: await EncryptPreLogin(JSON.stringify(rowData)),
  });
  const data = await response.text();
  const data1 = await DecryptPreLogin(data);
  const encodedString = data1;
  const res = JSON.parse(htmlDecode(encodedString));
  return res;
});
export const getCity = createAsyncThunk('UserManagement/GetCityList', async (params) => {
  const rowData = {
    stateId: `${params.body.stateID}`,
  };
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}account/GetCityList`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      PT: process.env.REACT_APP_API_KEY,
      'Content-Type': 'application/json',
    },
    body: await EncryptPreLogin(JSON.stringify(rowData)),
  });
  const data = await response.text();
  const data1 = await DecryptPreLogin(data);
  const encodedString = data1;
  const res = JSON.parse(htmlDecode(encodedString));
  return res;
});
export const generateMobileOtp = createAsyncThunk(
  'UserManagement/generateMobileOtp',
  async (params) => {
    const rowData = {
      type: 'MOBILE_VERIFICATION',
      mode: {
        mode1: 'SMS',
        mode2: '',
      },
      auth_mode: 0,
      receiverDetails: {
        mobileNo: params.mobileNumber,
        emailId: '',
      },
      ipaddress: params.ipAddress,
      substitutes: {
        additionalProp1: '',
        additionalProp2: '',
        additionalProp3: '',
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}Service/GeneratePreUserOTP`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          PT: process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
        body: await EncryptPreLogin(JSON.stringify(rowData)),
      },
    );
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  },
);
export const generateOTPVerification = createAsyncThunk(
  'UserManagement/generateMobileOtp',
  async (params) => {
    const rowData = {
      otp: params.otp,
      auth_mode: 0,
      type: 'CreatePreUser',
      mode: {
        mode1: 'SMS',
        mode2: '',
      },
      receiverDetails: {
        mobileNo: params.mobileNumber,
        emailId: '',
      },
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}Service/VerifyPreUserOTP`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        PT: process.env.REACT_APP_API_KEY,
        'Content-Type': 'application/json',
      },
      body: await EncryptPreLogin(JSON.stringify(rowData)),
    });
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  },
);
export const chePincodeAvialable = createAsyncThunk(
  'UserManagement/chePincodeAvialable',
  async (params) => {
    const rowData = {
      pincode: params.e.target.value,
      state: params.selectState.label,
      city: params.selectCity.label,
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}account/GetPincodeDetails`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        PT: process.env.REACT_APP_API_KEY,
      },
      body: await EncryptPreLogin(JSON.stringify(rowData)),
    });
    const data = await response.text();
    const data1 = await DecryptPreLogin(data);
    const encodedString = data1;
    const res = JSON.parse(htmlDecode(encodedString));
    return res;
  },
);
export const userSignup = createAsyncThunk('UserManagement/onboardingPreUser', async (params) => {
  const rowData = {
    nameTitle: '1',
    emailAddress: params.body.email,
    firstName: params.body.fname,
    middleName: params.body.mname ? params.body.mname : '',
    lastName: params.body.lname ? params.body.lname : '',
    primaryPhone: `${params.mobileNumber}`,
    primaryPhoneType: 0,
    secondaryPhone: `${params.mobileNumber}`,
    secondaryPhoneType: 0,
    role: Number(params.selectRole.value),
    senior_ID: Number(params.selectRole.seniorId),
    business_Name: params.body.company,
    business_Add: '',
    business_Contact_No: '',
    business_Type: '',
    state_ID: params.body.selectState,
    city_ID: params.body.selectCity,
    pincode: params.pincode,
    area: '',
    landMark: '',
    address: params.body.address,
    created_By: 0,
    plan_id: 0,
    aadhar_no: params.body.adharNumber,
    pan_no: params.body.panNumber,
    whatsapp_no: '',
    block_add: '',
    gram_panchayat: '',
    post_office: '',
    police_thana: '',
    gram: '',
    other: '',
    custom_Attributes: {
      ip: `${params.ipAddress}`,
      deviceId: 'string',
      deviceType: '',
      lattitude: `${params.lat}`,
      longitude: `${params.lng}`,
    },
  };
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}account/CreateUserPreLogin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      PT: process.env.REACT_APP_API_KEY,
    },
    body: await EncryptPreLogin(JSON.stringify(rowData)),
  });
  const data = await response.text();
  const data1 = await DecryptPreLogin(data);
  const encodedString = data1;
  const res = JSON.parse(htmlDecode(encodedString));
  return res;
});

export const updateSeniorMapping = createEncryptedAsyncThunk(
  'UserManagement/updateSeniorMapping',
  'account/UpdateSeniorId',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      changeUserId: params.mapDetails.userID,
      senior_Id: params.selectSenior ? params.selectSenior.value : 0,
    }),
  },
);
export const UserManagement = createSlice({
  name: 'UserManagement',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = UserManagement.actions;
export default UserManagement.reducer;
