import { createAsyncThunk } from '@reduxjs/toolkit';
import {  EncryptPreLogin, DecryptPreLogin, } from '../../middleware/CryptoMiddleWare';

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export const fetchWithEncryption = async ({ url, method, body, token, }) => {
  // console.log(body)
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    PT: process.env.REACT_APP_API_KEY,
    Authorization: `Bearer ${token}`,
    // 'X-XSRF-TOKEN':cfsf.csrfToken,
  };
  // console.log('REQUEST=>', `${url}`, body)
  // console.log('TOKEN=>', token)
  const response = await fetch(url, {
    method,
    headers,
    body:  body ? await EncryptPreLogin(JSON.stringify(body)) : undefined,
  });     
  const res = await response.text();
  // const abc ='AmnIslzGK4FBhK1Mh/q/G3Eu30jd8UDKkU2hJeJYzJtCCLUdUgkXXp7YH1MIW9bNQ9XxDCYEKZRNv/1CN+lWscg0gaaJDeiGAjziyGznfqr8q054ALPK1T2Bl6AlURebswASLV24VvnO6AjSoDq0Nfui5qob7tOkLwcv0Y9eZexso7mZ3ts6YqgcvwtAVOhwW2CpS+BLOU3Vb+akSTq8sS0K3R5e6yS0DPA1YgV5fBVpxRi7M7Z+K53OmUNNGHZcKdl3XztcrwAv3O1K6/Kg1s9EhCaU9da923KqFH/XGFKJjtJ6F5+SelaXUK5GqFryLQ=='
  // const xyz = await DecryptPreLogin(abc);
  // console.log(xyz);
  const data = await DecryptPreLogin(res);
  const encodedString = data;
  // console.log('RESPONSE =>', `${url}`, JSON.parse(htmlDecode(encodedString)))
  // const UserData = JSON.parse(htmlDecode(encodedString));
  // console.log(UserData)
  // const data1 = JSON.parse(Decrypt(data));
  // console.log('Response =>', data1);
  return JSON.parse(htmlDecode(encodedString));
};

export const createEncryptedAsyncThunk = (
  typePrefix,
  urlOrUrlCreator, // Can be a string or a function
  { method = 'POST', transUrl, prepareBody = (params) => params } = {},
  baseUrl = transUrl || process.env.REACT_APP_API_ENDPOINT,
) =>
  createAsyncThunk(typePrefix, async (params, { rejectWithValue }) => {
    try {
      // Determine the URL based on whether urlOrUrlCreator is a string or a function
      const fullUrl =
        typeof urlOrUrlCreator === 'function'
          ? `${baseUrl}${urlOrUrlCreator(params)}` // Call the function with params to get the URL
          : `${baseUrl}${urlOrUrlCreator}`; // Use the URL as is

      const body = method !== 'GET' ? prepareBody(params) : null;
      const response = await fetchWithEncryption({
        url: fullUrl,
        method,
        body,
        token: params.Usertoken,
      });   
      // console.log('Response => ', fullUrl, response)                                                                                                      
      return response;
    } catch (err) {
      return rejectWithValue(err.toString());
    }
  });
